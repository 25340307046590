import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Contact() {
  return (
    <Layout removeCTA fullWidth removeFooter>
      <SEO 
      title="Contact form" 
      description="Get in touch with us: book a training session, give feedback or share your success story 📈"
      />
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfQ4wba2pk1ix2Y2z48mxEaTKALUaWsl7y_23Bin5dRZPZUUQ/viewform?embedded=true"
        width="100%"
        height="812"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </Layout>
  );
}
